import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { BottomSheet, OutlinedIcon } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { CustomEventTypes } from '@src/config'
import { useAddInsuranceNavigation } from '@src/screens/NutritionistHub/hooks'
import { useNutritionistHubTracker } from '../hooks'

export const ClaimFreeVideoCallModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const route = useRoute<RouteProp<AppStackParamList, 'ClaimFreeVideoCallModal'>>()
  const { appointmentId, appointmentType } = route.params
  const track = useNutritionistHubTracker()
  const { canAddPolicy, navigateToInsurancePolicyForm } = useAddInsuranceNavigation()

  const onClaimFreeCallPress = () => {
    track(CustomEventTypes.NutritionistHubClaimFreeCallTapped)
    navigation.navigate('CallScheduling', {
      appointmentType,
      pendingAppointmentId: appointmentId,
      allowBackNavigation: true,
    })
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Claim Free Call', onPress: onClaimFreeCallPress }}
      secondaryButton={
        canAddPolicy
          ? { text: 'Try different insurance card', onPress: navigateToInsurancePolicyForm }
          : undefined
      }
      showDismissButton
      buttonDirection="column-reverse"
    >
      <OutlinedIcon iconName="warning" iconStyle={styles.icon} style={styles.warning} />
      <Text style={styles.content} type="title-2">
        Insurance Not Eligible
      </Text>
      <Text style={styles.content} type="large">
        Unfortunately, your insurance does not currently cover free video calls and we had to cancel
        your call.
      </Text>
      <Text style={styles.content} type="large">
        However, we would like to offer you 1 complimentary 30 minute video call with a nutritionist
        at no cost to you.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  icon: {
    color: 'theme.warning.base',
  },
  warning: {
    alignSelf: 'center',
  },
  content: {
    textAlign: 'center',
    margin: 8,
  },
})
