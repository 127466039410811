import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, useFeatureFlag } from '@components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { TypesSection } from '@screens/Settings/components/Integrations/DailyMeasurements/TypesSection'
import { ketoMojoSyncStatusSelector } from '@src/selectors/integrations'
import { IconSources } from '@src/assets/icons'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import { useIsActiveDexcom } from '@src/utils/hooks'
import { TerraProviders } from './Integrations/TerraProviders'
import { IntegrationTouchableSection } from './Integrations/IntegrationTouchableSection'

export const IntegrationSettings = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()

  const isLibreLinkup = useFeatureFlag(Feature.LibrelinkupConnection)
  const isActiveDexcom = useIsActiveDexcom()
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const ketoMojoSync = useSelector(ketoMojoSyncStatusSelector)
  const terraKetoMojoFlag = useFeatureFlag(Feature.TerraKetoMojo)

  const showOldKetoMojoIntegration = ketoMojoSync || !terraKetoMojoFlag

  return (
    <NavigationContainer title="Integrations" showLoadingIndicator>
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: (insets?.bottom || 0) + 16 }}
      >
        {!isActiveDexcom && isLibreLinkup && isCGMFeatureAvailable && (
          <IntegrationTouchableSection
            sectionTitle="FreeStyle Libre"
            imageSource={IconSources.freeStyleLibreIcon}
            onPress={() => navigation.navigate('LibreLinkupIntegration')}
          />
        )}
        <TerraProviders />
        {showOldKetoMojoIntegration && (
          <IntegrationTouchableSection
            sectionTitle="Keto-Mojo"
            imageSource={IconSources.ketomojo}
            onPress={() => navigation.navigate('KetoMojoIntegration')}
          />
        )}

        <TypesSection />
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
