import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { InlineAlert } from '@src/components/InlineAlert'
import { UiInlineAlert, UiStateNames } from '@src/types'
import { uiStatesSelector } from '@src/selectors/app'
import { useIsLibreLinkup } from '@src/utils/hooks'
import { StyleService } from '@src/style/service'

const SYNCED_AFTER_DAYS = 1

export const LinkupDelayPopup = () => {
  const uiStates = useSelector(uiStatesSelector)
  const libreLinkupEnabled = useIsLibreLinkup()
  const infoMessage =
    'There is approximately a 20 minute delay from when you scan in the LibreLink app ' +
    'to data showing up in the Nutrisense app'
  const alert = (
    <InlineAlert style={styles.popup} category={UiInlineAlert.Info} message={infoMessage} />
  )

  if (!libreLinkupEnabled) {
    return null
  }

  const linkupSyncedState = uiStates.find((uiState) => uiState.name === UiStateNames.SyncedLinkup)
  const linkupSyncedAt = linkupSyncedState?.createdAt || null

  // data hasn't synced yet
  if (!linkupSyncedAt) {
    return alert
  }

  // User has been synced for a while
  if (moment().diff(linkupSyncedAt, 'days') >= SYNCED_AFTER_DAYS) {
    return null
  }

  return alert
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
