import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { sensorSelector } from '@src/selectors/sensor'
import { useIsActiveDexcom, useIsLibreLinkup, useLibre3NativeEnabled } from '@src/utils/hooks'

export const useDisableInAppScan = () => {
  const sensor = useSelector(sensorSelector)
  const isActiveDexcom = useIsActiveDexcom()

  const isLibreLinkup = useIsLibreLinkup()
  const abbottPartnershipEnabled = useFeatureFlag(Feature.AbbottPartnership)
  const { uxSensorModePendingLibre3, currentSensorIsLibre3 } = useLibre3NativeEnabled()

  const disableInAppScan =
    abbottPartnershipEnabled ||
    isLibreLinkup ||
    isActiveDexcom ||
    currentSensorIsLibre3 ||
    (uxSensorModePendingLibre3 && !sensor)

  return disableInAppScan
}
