import React from 'react'
import { useSelector } from 'react-redux'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { InlineAlert } from '@src/components/InlineAlert'
import { useIsActiveDexcom } from '@src/utils/hooks'
import { DexcomConnectionStatus, UiInlineAlert } from '@src/types'
import { dexcomConnectionSelector } from '@src/selectors/app'

export const MissingDexcomIntegrationPopup = () => {
  const navigation = useNavigation()
  const dexcomConnection = useSelector(dexcomConnectionSelector)
  const isActiveDexcom = useIsActiveDexcom()

  const hidePopup = !isActiveDexcom || dexcomConnection?.status === DexcomConnectionStatus.Active

  if (hidePopup) {
    return null
  }

  const onPress = () => {
    navigation.navigate('SensorSettings')
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Enable dexcom integration"
      activeOpacity={0.7}
      onPress={onPress}
    >
      {dexcomConnection?.status === DexcomConnectionStatus.Invalidated ? (
        <InlineAlert
          style={styles.popup}
          category={UiInlineAlert.Danger}
          // eslint-disable-next-line max-len
          message="Your Dexcom G7 data integration authentication has been reset. Please authenticate again to integrate your data."
        />
      ) : (
        <InlineAlert
          style={styles.popup}
          category={UiInlineAlert.Warning}
          message="Connect your Dexcom G7 data to continue"
        />
      )}
    </TouchableOpacity>
  )
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
