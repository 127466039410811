import React from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { Icon } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useIsDarkMode } from '@src/config/theme'

export interface WarningBannerProps {
  body: React.ReactNode
  accessibilityLabel: string
  image: React.ReactNode
  onPress: () => void
  containerStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
}

export const WarningBanner = ({
  body,
  accessibilityLabel,
  image,
  onPress,
  containerStyle,
  iconStyle,
}: WarningBannerProps) => {
  const styles = useStyleSheet(themedStyles)
  const isDarkMode = useIsDarkMode()
  const theme = useTheme()

  const iconColor = isDarkMode ? theme['theme.tooltip.textPrimary'] : theme['theme.primary.base']

  return (
    <TouchableOpacity
      accessibilityLabel={accessibilityLabel}
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      {image}
      {body}
      <Icon
        name="caret-right"
        size="16"
        weight="bold"
        style={[{ color: iconColor } as ViewStyle, iconStyle]}
      />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    margin: 16,
    padding: 16,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'theme.marketing.yellow',
  },
  textContainer: {
    flex: 1,
    marginRight: 12,
  },
})
