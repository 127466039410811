import { gql } from '@apollo/client'
import { CORE_INSURANCE_POLICY_FIELDS } from '@src/graphql/fragments/insurancePolicy'

const CREATE_INSURANCE_POLICY = gql`
  ${CORE_INSURANCE_POLICY_FIELDS}
  mutation createInsurancePolicy(
    $memberId: String!
    $groupId: String
    $holderRelationship: UserInsurancePolicyHolderRelationship!
    $holderFirstName: String
    $holderLastName: String
    $holderDateOfBirth: ISO8601Date
    $kind: UserInsurancePolicyKind!
  ) {
    createInsurancePolicy(
      memberId: $memberId
      groupId: $groupId
      holderRelationship: $holderRelationship
      holderFirstName: $holderFirstName
      holderLastName: $holderLastName
      holderDateOfBirth: $holderDateOfBirth
      kind: $kind
    ) {
      ...CoreInsurancePolicyFields
    }
  }
`

export default CREATE_INSURANCE_POLICY
