import { RouteProp, useNavigation, useRoute } from '@react-navigation/core'
import { groupBy, upperCase, upperFirst } from 'lodash'
import { useEffect, useRef, useCallback, useState, useMemo, useContext } from 'react'
import { Dimensions, Keyboard, KeyboardEvent } from 'react-native'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import {
  SensorTypes,
  Libre3BluetoothManagerConnectionState as ConnectionState,
} from 'react-native-freestyle-libre'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ReactNativeFile } from '@src/utils/image'
import { AppStackParamList, AuthStackParamList } from '@src/navigation/types'
import { Feature, useFeatureFlag } from '@src/components'
import {
  addonLimitsSelector,
  userSelector,
  onboardingSkippedSelector,
  uiStatesSelector,
  uxSensorModeKindSelector,
} from '@src/selectors/app'
import { SubscriptionStatus, UiStateNames, UserExperienceSensorModeKind } from '@src/types'
import { ADDON_MAX_QUANTITY } from '@src/screens/Marketplace/constants/constants'
import Device from '@config/device'
import { sensorSelector } from '@src/selectors/sensor'
import { ScanningContext } from '@src/context/scanningContext'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { chooseFromLibrary } from './image'
import { useSnack } from './navigatorContext'
import { CAMERA_DENIED_SNACK_MESSAGE } from './camera'
import { getGlucoseValue } from './units'

export const useAllowedAddonQuantity = () => {
  const addonLimits = useSelector(addonLimitsSelector)
  const addonLimitByProductId = groupBy(addonLimits, 'productId')

  const getAllowedAddonQuantity = (
    addonProductId: string,
  ): { allowedAddonQuantity: number; addonLimit: number | null } => {
    const [addonLimit] = addonLimitByProductId[addonProductId]
    return {
      allowedAddonQuantity:
        addonLimit && typeof addonLimit.limit === 'number'
          ? addonLimit.limit - addonLimit.purchasedAmount
          : ADDON_MAX_QUANTITY,
      addonLimit: addonLimit?.limit ?? null,
    }
  }

  return {
    addonLimitByProductId,
    getAllowedAddonQuantity,
  }
}

export const useStopMultipleInvocations = (functionToInvoke: (...args: any) => any) => {
  const invokedRef = useRef(false)

  const invoke = useCallback(
    (...args: any) => {
      if (invokedRef.current) {
        return
      }

      invokedRef.current = true

      return functionToInvoke(...args)
    },
    [functionToInvoke],
  )

  return invoke
}

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useCancelRoute = () => {
  const route = useRoute<
    RouteProp<
      AppStackParamList,
      | 'AddActivity'
      | 'AddMeasurement'
      | 'AddMeal'
      | 'EditMeal'
      | 'EditAccountDetails'
      | 'CustomIngredient'
      | 'IngredientSearch'
      | 'Marketplace'
    >
  >()
  const { params } = route
  const cancel = params?.cancel

  return {
    route,
    cancel,
  }
}

export const useCancelModal = ({
  goBack,
  isModified,
  itemName,
  title,
  cancelText,
  confirmText,
}: {
  goBack: () => void
  isModified: boolean
  itemName?: string
  title?: string
  cancelText?: string
  confirmText?: string
}) => {
  const navigation = useNavigation()
  const { route, cancel } = useCancelRoute()

  useEffect(() => {
    if (cancel) {
      navigation.setParams({ cancel: undefined })
      goBack()
    }
  }, [cancel, goBack, navigation])

  const buttonItemText = itemName
    ? itemName
        ?.split(' ')
        .map((word) => upperFirst(word))
        .join(' ')
    : ''

  const openCancelModal = () => {
    if (!isModified) {
      goBack()
      return
    }

    navigation.navigate('CancelModal', {
      title: title || `Do you really want to cancel the new ${itemName}?`,
      cancelText: cancelText || `No, Continue New ${buttonItemText}`,
      confirmText: confirmText || `Yes, Cancel New ${buttonItemText}`,
      parentScreen: route.name,
    })
  }

  return openCancelModal
}

export const usePhotoUpload = (callback: (photo: ReactNativeFile) => void) => {
  const navigation = useNavigation()
  const route = useRoute<
    RouteProp<AppStackParamList, 'AddMeal' | 'EditMeal'> | RouteProp<AuthStackParamList, 'SignUp'>
  >()
  const params = route.params || { photo: undefined }
  const { photo } = params
  const showSnack = useSnack()

  useEffect(() => {
    if (!photo) {
      return
    }

    navigation.setParams({ photo: undefined })

    callback(photo)
  }, [navigation, photo, callback])

  const launchCameraScreen = () =>
    navigation.navigate('Camera', { parentScreen: route.name, parentParams: route.params })

  const onCameraPermissionDenied = () => {
    showSnack(CAMERA_DENIED_SNACK_MESSAGE, null, 'warning')
  }

  const launchImageLibrary = () => chooseFromLibrary(callback, onCameraPermissionDenied)

  return { launchCameraScreen, launchImageLibrary }
}

export const useKeyboardVisibility = (optimistic: boolean): boolean => {
  const [isVisible, setIsVisible] = useState(false)

  const showEventName = optimistic ? 'keyboardWillShow' : 'keyboardDidShow'
  const hideEventName = optimistic ? 'keyboardWillHide' : 'keyboardDidHide'

  useEffect(() => {
    const showEventSubscription = Keyboard.addListener(showEventName, () => setIsVisible(true))
    const hideEventSubscription = Keyboard.addListener(hideEventName, () => setIsVisible(false))

    return () => {
      showEventSubscription.remove()
      hideEventSubscription.remove()
    }
  }, [showEventName, hideEventName])

  return isVisible
}

export const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0)

  useEffect(() => {
    const onKeyboardDidShow = (event: KeyboardEvent) =>
      setKeyboardHeight(event.endCoordinates.height)
    const onKeyboardDidHide = () => setKeyboardHeight(0)

    const showListener = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow)
    const hideListener = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide)

    return () => {
      showListener.remove()
      hideListener.remove()
    }
  }, [])

  return keyboardHeight
}

export const useAvailableScreenHeight = () => {
  const insets = useSafeAreaInsets()
  const keyboardHeight = useKeyboardHeight()

  return Dimensions.get('window').height - keyboardHeight - insets.top - insets.bottom
}

export const useDebouncedCallback = (
  ...[callback, delay, options]: Parameters<typeof debounce>
) => {
  const callbackRef = useRef<any>()
  callbackRef.current = callback

  return useMemo(() => {
    return debounce((...args) => callbackRef.current(...args), delay, options)
  }, [delay, options])
}

export const useAllowSkipPurchaseFlow = () => {
  const allowSkipPurchaseFlowFeatureFlag = useFeatureFlag(Feature.AllowSkipPurchaseFlow)

  return allowSkipPurchaseFlowFeatureFlag
}

export const useShouldShowPurchaseFlow = () => {
  const onboardingSkipped = useSelector(onboardingSkippedSelector)

  const uiStates = useSelector(uiStatesSelector)

  const checkoutCompletedUiState = uiStates.find(
    (uiState) => uiState.name === UiStateNames.CheckoutCompleted,
  )

  const checkoutCompleted = checkoutCompletedUiState?.value || false

  return !onboardingSkipped && !checkoutCompleted
}

export const useAllowSignInWithApple = () => Device.ios

export const useAllowSignInWithGoogle = () => !Device.web

export const useAllowDietitianSupport = () => {
  const user = useSelector(userSelector)

  const ongoingDietitianSubscription =
    user && 'ongoingDietitianSubscription' in user ? user.ongoingDietitianSubscription : undefined

  if (!ongoingDietitianSubscription) {
    return false
  }

  const ongoingDietitianSubscriptionStatus =
    'status' in ongoingDietitianSubscription ? ongoingDietitianSubscription.status : undefined

  return (
    ongoingDietitianSubscriptionStatus === SubscriptionStatus.Active ||
    ongoingDietitianSubscriptionStatus === SubscriptionStatus.Trialing
  )
}

/**
 * Custom hook that determines if Libre 3 native support is enabled.
 * @returns An object containing the following properties:
 *   - uxSensorModePendingLibre3: boolean. Show a user experience indicating that a user will receive a libre 3 sensor,
 *     but may not currently have one.
 *   - currentSensorIsLibre3: boolean. This user is currently using an activated libre 3 sensor.
 */
export const useLibre3NativeEnabled = () => {
  const currentSensor = useSelector(sensorSelector)
  const uxSensorModePendingLibre3 =
    useSelector(uxSensorModeKindSelector) === UserExperienceSensorModeKind.PendingLibre3

  const currentSensorIsLibre3 = currentSensor?.model === SensorTypes.Libre3

  return {
    uxSensorModePendingLibre3,
    currentSensorIsLibre3,
  }
}

export const useIsLibreLinkup = () => {
  const isLibreLinkupEnabled = useFeatureFlag(Feature.LibrelinkupConnection)
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  return (
    (isLibreLinkupEnabled && uxSensorModeKind === UserExperienceSensorModeKind.LibreLinkup) || false
  )
}

export const useDexcomIntegration = () => {
  const isDexcomEnabled = useFeatureFlag(Feature.DexcomIntegration)
  const isDexcomResearch = useIsDexcomResearch()
  const isActiveDexcom = useIsActiveDexcom()

  return isDexcomEnabled && (isDexcomResearch || isActiveDexcom)
}

export const useIsDexcomResearch = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  return [
    UserExperienceSensorModeKind.PendingResearchDexcom,
    UserExperienceSensorModeKind.ActiveResearchDexcom,
  ].includes(uxSensorModeKind as UserExperienceSensorModeKind)
}

export const useIsActiveDexcom = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  return [
    UserExperienceSensorModeKind.ActiveResearchDexcom,
    UserExperienceSensorModeKind.ActivePrescriptionDexcomG7,
  ].includes(uxSensorModeKind as UserExperienceSensorModeKind)
}

export const useShowStartDexcomPrompts = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const startDexcomParticipationEnabled = useFeatureFlag(Feature.StartDexcomParticipation)

  return (
    startDexcomParticipationEnabled &&
    uxSensorModeKind === UserExperienceSensorModeKind.PendingResearchDexcom
  )
}

export const useConnectingToSensorViaBluetooth = () => {
  const { bluetoothConnectionState } = useContext(ScanningContext)

  return (
    bluetoothConnectionState === ConnectionState.Connecting ||
    bluetoothConnectionState === ConnectionState.SecurityChallenge
  )
}

/**
 * Custom hook to retrieve the live glucose value and time from the scanning context.
 * It applies baseline adjustment and unit system conversion if necessary.
 * @returns An object containing the real-time glucose value and time.
 */
export const useLiveGlucoseValue = () => {
  // eslint-disable-next-line prefer-const
  let { realTimeGlucoseValue, realTimeGlucoseTime } = useContext(ScanningContext)
  const baselineAdjustment = useSelector(sensorSelector)?.baselineAdjustment || 0
  const unitSystem = useSelector(unitSystemStoreStateSelector)

  if (realTimeGlucoseValue) {
    realTimeGlucoseValue = getGlucoseValue(unitSystem, realTimeGlucoseValue + baselineAdjustment)
  }

  return { realTimeGlucoseValue, realTimeGlucoseTime }
}

export const useUserInitials = () => {
  const { firstName = '', lastName = '' } = useSelector(userSelector) ?? {}

  const initials = useMemo(() => {
    return `${upperCase(firstName[0])}${upperCase(lastName[0])}`
  }, [firstName, lastName])

  return initials
}
