import React from 'react'
import { BottomSheet, OutlinedIcon } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useAddInsuranceNavigation } from '@src/screens/NutritionistHub/hooks'

export const InsuranceNotEligibleModal = () => {
  const styles = useStyleSheet(themedStyles)
  const { canAddPolicy, navigateToInsurancePolicyForm } = useAddInsuranceNavigation()

  return (
    <BottomSheet
      primaryButton={
        canAddPolicy
          ? { text: 'Try different insurance card', onPress: navigateToInsurancePolicyForm }
          : undefined
      }
      showDismissButton
    >
      <OutlinedIcon iconName="warning" iconStyle={styles.icon} style={styles.warning} />
      <Text style={styles.content} type="title-2">
        Insurance Not Eligible
      </Text>
      <Text style={styles.content} type="large">
        Unfortunately, your insurance does not currently cover free video calls and we had to cancel
        your call.
      </Text>
      <Text style={styles.content} type="large">
        Want to try a different insurance? Click the link below, and we'll recheck your benefits for
        you!
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  icon: {
    color: 'theme.warning.base',
  },
  warning: {
    alignSelf: 'center',
  },
  content: {
    textAlign: 'center',
    margin: 8,
  },
})
