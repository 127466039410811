import React from 'react'
import { useIsActiveDexcom } from '@src/utils/hooks'
import { DexcomSensorSettingsContainer } from './DexcomSensorSettings'
import { SensorSettingsContainer } from './SensorSettings'

export const SensorSettingsScreen = () => {
  const isActiveDexcom = useIsActiveDexcom()

  if (isActiveDexcom) {
    return <DexcomSensorSettingsContainer />
  }

  return <SensorSettingsContainer />
}
