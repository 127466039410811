import materialCommunityIcons from 'react-native-vector-icons/glyphmaps/MaterialCommunityIcons.json'
import materialIconNames from 'react-native-vector-icons/glyphmaps/MaterialIcons.json'
import { ImageIconName } from '@src/assets/icons/types'
import { IconPack } from './types'
import { EvaIconName } from './eva-icons'

export type MaterialIconName = keyof typeof materialIconNames
export type MaterialCommunityIconName = keyof typeof materialCommunityIcons
export interface IconDescriptor<Pack extends IconPack = IconPack> {
  pack: `${Pack}`
  name: Pack extends IconPack.CustomImage
    ? ImageIconName
    : Pack extends IconPack.Material
    ? MaterialIconName
    : Pack extends IconPack.MaterialCommunity
    ? MaterialCommunityIconName
    : EvaIconName
}
