import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Input, Text } from '@components/base'
import { SecureInput } from '@src/components'
import { Validators } from '@src/utils'
import { useSnack } from '@src/utils/navigatorContext'

export const LibreLoginForm = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const isSubmittingRef = useRef(false)

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      libreEmail: '',
      librePassword: '',
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (isSubmittingRef.current) {
      return
    }

    isSubmittingRef.current = true

    dispatch({
      type: 'settings/initLibreLinkupConnection',
      payload: data,
      complete: () => {
        isSubmittingRef.current = false
      },
      failure: (error: any) => {
        showSnack(error?.message || 'Please check your email and password', null, 'error')
      },
    })
  })

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text type="regular" style={styles.text}>
            To complete your connection, sign in with the{' '}
            <Text type="regular" bold>
              same credentials{' '}
            </Text>
            <Text type="regular">you used in the LibreLink app.</Text>
          </Text>
        </View>
        <Controller
          control={control}
          name="libreEmail"
          rules={{ required: true, validate: Validators.EmailValidator }}
          render={({ field }) => (
            <Input
              {...field}
              autoCapitalize="none"
              hasError={!!errors.libreEmail}
              errorText="Invalid email address"
              placeholder="Write your LibreLink email"
              label="Email"
              style={styles.input}
            />
          )}
        />
        <Controller
          control={control}
          name="librePassword"
          rules={{ required: true, validate: Validators.LibrePasswordValidator }}
          render={({ field }) => (
            <SecureInput
              {...field}
              hasError={!!errors.librePassword}
              errorText="Password should be 8 to 36 characters"
              placeholder="Write your LibreLink password"
              label="Password"
              returnKeyType="done"
              style={styles.input}
              onSubmitEditing={onSubmit}
            />
          )}
        />
      </View>
      <Button
        type="primary"
        size="block"
        disabled={!isValid}
        accessibilityLabel="Login"
        onPress={onSubmit}
      >
        Login
      </Button>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  textContainer: {
    marginBottom: 48,
  },
  text: {
    textAlign: 'center',
  },
  input: {
    marginBottom: 16,
  },
})
