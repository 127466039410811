import React from 'react'
import { View, Image, ImageStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useIsDarkMode } from '@src/config/theme'
import { IconSources } from '@src/assets/icons'
import { WarningBanner } from './WarningBanner'

const END_SCAN_DATE = '2024-11-29'

export const ScanMigrationBanner = () => {
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation()

  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const today = moment()
  const targetDate = moment(END_SCAN_DATE)
  const daysRemaining = targetDate.diff(today, 'days')

  const daysText = daysRemaining > 1 ? 'days' : 'day'
  const daysRemainingText = daysRemaining > 0 ? `${daysRemaining} ${daysText} left` : 'Last Day'
  // same color regardless of light/dark mode
  const imageBackgroundColor = '#FBEFC9'

  // stop showing this banner when cutoff date is passed
  if (daysRemaining < 0) {
    return null
  }

  const onPress = () => {
    navigation.navigate('ScanMigrationGuide')
  }

  const textColor = isDarkMode ? theme['theme.tooltip.textPrimary'] : theme['theme.text.primary']
  const image = (
    <View style={[styles.imageBorder, { backgroundColor: imageBackgroundColor }]}>
      <Image source={IconSources.freeStyleLibreIcon} style={styles.image as ImageStyle} />
    </View>
  )

  const body = (
    <View style={styles.textContainer}>
      <Text type="regular" style={{ color: textColor }}>
        Integrate with FreeStyle LibreLink app to keep scanning before Nov 29th.
        <Text type="regular" bold style={styles.highlightText}>
          {' '}
          {daysRemainingText}
        </Text>{' '}
      </Text>
    </View>
  )

  return (
    <WarningBanner
      accessibilityLabel="Migrate to new scanning experience"
      onPress={onPress}
      image={image}
      body={body}
    />
  )
}

const themedStyles = StyleService.create({
  imageBorder: {
    borderRadius: 12,
    padding: 4,
    marginRight: 12,
    overflow: 'hidden',
  },
  image: {
    width: 48,
    height: 48,
  },
  textContainer: {
    flex: 1,
    marginRight: 12,
  },
  highlightText: {
    color: 'theme.marketing.brown',
  },
})
