import { useCallback } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { useSnack } from '@src/utils'
import { CustomEventTypes, ErrorMessages } from '@src/config'
import { canCreateNewPolicySelector } from '@src/screens/InsuranceStatus/models/insuranceStatus.selectors'
import { useNutritionistHubTracker } from '../hooks'

export const useAddInsuranceNavigation = () => {
  const navigation = useNavigation()
  const insuranceResubmissionEnabled = useFeatureFlag(Feature.InsuranceResubmission)
  const canCreateNewPolicy = useSelector(canCreateNewPolicySelector)
  const canAddPolicy = insuranceResubmissionEnabled && canCreateNewPolicy
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const track = useNutritionistHubTracker()

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'insuranceStatus/fetchInsurancePolicies',
        failure: (error: any) => {
          const errorMessage = error?.message || ErrorMessages.ServerError
          showSnack(errorMessage, null, 'error')
        },
      })
    }, [dispatch, showSnack]),
  )

  const navigateToInsurancePolicyForm = () => {
    track(CustomEventTypes.NutritionistHubAddNewInsuranceTapped)
    navigation.navigate('InsurancePolicyForm')
  }

  return {
    canAddPolicy,
    navigateToInsurancePolicyForm,
  }
}
