import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { groupBy, uniqueId } from 'lodash'
import Orientation from 'react-native-orientation-locker'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Debug, Device } from '@config'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import {
  eventsObjectsSelector,
  eventsCalendarSelector,
  todayEventsSelector,
} from '@src/selectors/events'
import { useHasLargeScreen, useShouldLockToPortrait } from '@src/config/device'
import { Intercom, useDispatchAsync } from '@utils'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { NotificationBanner } from '@src/components/notifications/NotificationBanner'
import { NotificationEngineScreen } from '@src/types'
import { dexcomConnectionSelector } from '@src/selectors/app'
import { IconButton } from '@components/base'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { useAllMemberNotes } from '@src/screens/NutritionistHub'
import { Timeline } from '@src/components/Timeline/Timeline'
import { useFetchStats } from '@src/screens/Insights/utils/hooks'
import { useIsLibreLinkup } from '@src/utils/hooks'
import { GroupedEvent } from '../models/events.types'
import { useChartInteractions } from '../utils/useChartInteractions'
import { OnboardingChecklistBanner } from '../components/Banners/OnboardingChecklistBanner'
import { VideoCallsUpsellBanner } from '../components/Banners/VideoCallsUpsellBanner'
import { useOnboardingModals } from '../utils/hooks'
import { DailyActionsSection } from '../../DailyActions/DailyActionsSection'
import { EventsList } from '../components/EventsList'
import { HomeScreenChart } from '../components/HeroChart/HomeScreenChart'
import { ScanMigrationWarningBanner } from '../components/Banners/ScanMigrationWarningBanner'
import { MessageIcon } from './MessageIcon'
import { ChatbotIcon } from './ChatbotIcon'

export const PLUS_MENU_BOTTOM_MARGIN = 14
const PAGINATION_CONFIG = { pageSize: 20 }

export const EventsContainer = () => {
  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()
  const styles = useStyleSheet(themedStyle)
  const isDataLoading = useRef(false)
  const insets = useSafeAreaInsets()

  useOnboardingModals()
  useAllMemberNotes()

  const areDailyActionsEnabled = useFeatureFlag(Feature.UserDailyActions)

  const dexcomConnection = useSelector(dexcomConnectionSelector)
  const isLibreLinkup = useIsLibreLinkup()

  const eventsCalendar = useSelector(eventsCalendarSelector)

  const today = moment().format(DATE_FORMAT)

  const { startDate, endDate } = areDailyActionsEnabled
    ? { startDate: today, endDate: today }
    : eventsCalendar

  const events = useSelector(areDailyActionsEnabled ? todayEventsSelector : eventsObjectsSelector)

  const [multiSliderValues, setMultiSliderValues] = useState<[number, number]>()

  const { fetchStats } = useFetchStats()

  const filteredEvents = useMemo(() => {
    if (!multiSliderValues) {
      return events
    }

    const sliderStart = moment.unix(multiSliderValues[0])
    const sliderEnd = moment.unix(multiSliderValues[1])
    const selectedEvents = events.filter((event) =>
      moment(event.occurredAt).isBetween(sliderStart, sliderEnd, undefined, '[]'),
    )

    return selectedEvents
  }, [events, multiSliderValues])

  const groupedEvents: GroupedEvent[] = useMemo(() => {
    const dateGroups = groupBy(filteredEvents, (i) => moment(i.occurredAt).format(DATE_FORMAT))
    return Object.entries(dateGroups).map(([date, data], index) => ({
      id: uniqueId(date),
      index,
      title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
      occurredAt: moment(date),
      data,
    }))
  }, [filteredEvents])

  const {
    listRef,
    primaryChartRef,
    secondaryChartRef,
    highlightedIndex,
    onChartLoadEnd,
    onChartHover,
    onEventListViewableItemsChanged,
    onEventListDragStart,
    onEventListMomentumScrollEnd,
  } = useChartInteractions(groupedEvents)

  const shouldLockToPortrait = useShouldLockToPortrait()

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      console.log('Events:onFocus')
      if (shouldLockToPortrait) {
        Orientation.lockToPortrait()
      }
    })
    return unsubscribe
  }, [navigation, shouldLockToPortrait])

  const refreshData = useCallback(
    async (useCache: boolean) => {
      if (isDataLoading.current) {
        return
      }

      const promises = [
        dispatchAsync({
          type: 'events/fetchNutrition',
          payload: {
            filter: {
              startDate,
              endDate,
            },
          },
          useCache,
        }),
        dispatchAsync({
          type: 'events/fetchCharts',
          payload: {
            filter: {
              startDate,
              endDate,
            },
          },
          useCache,
        }),
      ]

      if (areDailyActionsEnabled) {
        promises.push(
          dispatchAsync({
            type: 'dailyActions/list',
          }),
        )
      }

      isDataLoading.current = true

      try {
        await Promise.all(promises)
      } finally {
        isDataLoading.current = false
      }
    },
    [areDailyActionsEnabled, dispatchAsync, endDate, startDate],
  )

  const shouldRefreshWithoutCacheOnFocus =
    moment(startDate).isSame(moment().format(DATE_FORMAT)) && (!!dexcomConnection || isLibreLinkup)

  const loadDataOnFocus = useCallback(() => {
    refreshData(!shouldRefreshWithoutCacheOnFocus)
    if (areDailyActionsEnabled) {
      fetchStats({ startDate, endDate })
    }
  }, [
    areDailyActionsEnabled,
    endDate,
    fetchStats,
    refreshData,
    shouldRefreshWithoutCacheOnFocus,
    startDate,
  ])

  useFocusEffect(loadDataOnFocus)

  const hasLargeScreen = useHasLargeScreen()
  const rightAccessories: NavigationBarAccessory[] = []

  if (useFeatureFlag(Feature.Chatbot) && !Device.web) {
    rightAccessories.push({
      renderIconComponent: () => <ChatbotIcon />,
      onPress: () => navigation.navigate('Chat'),
      accessibilityLabel: 'Chat',
    })
  }

  const showMessages = Debug.shouldEnableIntercom()
  if (showMessages) {
    rightAccessories.push({
      renderIconComponent: () => <MessageIcon />,
      onPress: () => Intercom.showIntercomMessenger({ source: 'Events' }),
      accessibilityLabel: 'Message',
    })
  }

  return (
    <AccountAvatarNavigationBar
      rightAccessories={rightAccessories}
      style={styles.container}
      title={areDailyActionsEnabled ? 'Today' : null}
      hideLeftIcon={hasLargeScreen}
      navigationBarProps={{
        dateSelectorType: 'events',
        allowRangeSelection: false,
        backgroundColor: 'transparent',
      }}
    >
      <ScanMigrationWarningBanner />
      <NotificationBanner screen={NotificationEngineScreen.Home} calendarDate={startDate} />
      <OnboardingChecklistBanner />
      <VideoCallsUpsellBanner />
      {areDailyActionsEnabled ? (
        <DailyActionsSection
          HeroChartComponent={
            <HomeScreenChart
              primaryChartRef={primaryChartRef}
              secondaryChartRef={secondaryChartRef}
              onChartLoadEnd={onChartLoadEnd}
            />
          }
        />
      ) : (
        <>
          <Timeline
            primaryChartRef={primaryChartRef}
            secondaryChartRef={secondaryChartRef}
            onChartHover={onChartHover}
            onChartLoadEnd={onChartLoadEnd}
            multiSliderValuesChange={setMultiSliderValues}
          />
          <EventsList
            listRef={listRef}
            name="events"
            dataPath="events.events"
            items={groupedEvents}
            highlightedIndex={highlightedIndex}
            filter={{
              scope: 'events',
              startDate,
              endDate,
            }}
            pagination={PAGINATION_CONFIG}
            onDataLoadStart={refreshData}
            useCache
            onViewableItemsChanged={onEventListViewableItemsChanged}
            onDragStart={onEventListDragStart}
            onMomentumScrollEnd={onEventListMomentumScrollEnd}
          />
        </>
      )}
      <IconButton
        accessibilityLabel="Open Menu"
        iconName="plus"
        size="l"
        style={[
          styles.toggle,
          { bottom: hasLargeScreen && insets.bottom ? insets.bottom : PLUS_MENU_BOTTOM_MARGIN },
        ]}
        type="primary"
        onPress={() => navigation.navigate('ShowActions')}
      />
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  toggle: {
    position: 'absolute',
    right: 20,
  },
})
