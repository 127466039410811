import React, { useRef, useState } from 'react'
import { TouchableWithoutFeedback, View, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import BaseBottomSheet, {
  BottomSheetProps as BaseBottomSheetProps,
  BottomSheetView,
} from '@gorhom/bottom-sheet'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, ButtonProps, Text } from '@components/base'
import { ScrollViewWithFade } from '@components'
import { Device } from '@config'
import { useGoBack } from '@utils'
import { useKeyboardHeight } from '@utils/hooks'
import { useReducedMotion } from '@src/config/accessibility'

export interface ActionButtonProps
  extends Omit<ButtonProps, 'accessibilityLabel' | 'children' | 'type' | 'size'> {
  text: string
}

interface BottomSheetProps extends BaseBottomSheetProps {
  children: React.ReactNode
  dismissOnBackdropPress?: boolean
  header?: string | React.ReactNode
  primaryButton?: ActionButtonProps
  secondaryButton?: ActionButtonProps
  buttonDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  showDismissButton?: boolean
}

export const BottomSheet = ({
  children,
  dismissOnBackdropPress = true,
  header,
  primaryButton,
  secondaryButton,
  buttonDirection = 'row',
  showDismissButton = false,
  ...restProps
}: BottomSheetProps) => {
  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()
  const insets = useSafeAreaInsets()
  const dimensions = useWindowDimensions()
  const keyboardHeight = useKeyboardHeight()
  const reduceMotionEnabled = useReducedMotion()

  const bottomSheetRef = useRef<BaseBottomSheet>(null)

  const [headerHeight, setHeaderHeight] = useState(0)
  const [buttonsHeight, setButtonsHeight] = useState(0)

  const [contentHeight, setContentHeight] = useState(0)

  const handleDismiss = () => {
    bottomSheetRef.current?.close()
    goBack()
  }

  const handleBackdropPress = () => {
    if (dismissOnBackdropPress) {
      handleDismiss()
    }
  }

  const handleSheetChange = (index: number) => {
    if (index === -1) {
      handleDismiss()
    }
  }

  const renderHeader = () => {
    if (!header) {
      return null
    }

    if (typeof header === 'string') {
      return (
        <Text type="title-3" style={styles.title}>
          {header}
        </Text>
      )
    }

    return header
  }

  const hasAnyButton = !!(primaryButton || secondaryButton)
  const hasBothButtons = !!(primaryButton && secondaryButton)

  const paddingBottom = keyboardHeight === 0 ? insets.bottom + 16 : 16

  const maxSheetHeight = dimensions.height - 80
  const maxContentHeight = maxSheetHeight - headerHeight - buttonsHeight - paddingBottom

  return (
    <View style={styles.flexGrow}>
      <TouchableWithoutFeedback accessibilityLabel="Backdrop" onPress={handleBackdropPress}>
        <View style={styles.flexGrow} />
      </TouchableWithoutFeedback>
      <BaseBottomSheet
        animateOnMount={!reduceMotionEnabled}
        enableDynamicSizing
        enablePanDownToClose
        bottomInset={Device.ios ? keyboardHeight : 0}
        backgroundStyle={styles.background}
        contentHeight={headerHeight + contentHeight + buttonsHeight + paddingBottom}
        handleStyle={styles.handle}
        handleIndicatorStyle={styles.handleIndicator}
        maxDynamicContentSize={maxSheetHeight}
        ref={bottomSheetRef}
        onChange={handleSheetChange}
        {...restProps}
      >
        <BottomSheetView style={[styles.container, { paddingBottom }]}>
          <View onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}>
            {renderHeader()}
          </View>
          <ScrollViewWithFade
            style={[styles.scrollView, { maxHeight: maxContentHeight }]}
            scrollViewStyle={styles.scrollContainer}
            onContentSizeChange={(_, height) => setContentHeight(height)}
          >
            {children}
          </ScrollViewWithFade>
          <View
            onLayout={(e) => setButtonsHeight(e.nativeEvent.layout.height)}
            style={{ flexDirection: buttonDirection, paddingTop: hasAnyButton ? 16 : 0 }}
          >
            {secondaryButton && (
              <Button
                accessibilityLabel={secondaryButton.text}
                type="outline"
                size="block"
                style={[styles.flexGrow, secondaryButton.style]}
                {...secondaryButton}
              >
                {secondaryButton.text}
              </Button>
            )}
            {hasBothButtons && <View style={styles.buttonSpacer} />}
            {primaryButton && (
              <Button
                accessibilityLabel={primaryButton.text}
                type="primary"
                size="block"
                style={[styles.flexGrow, primaryButton.style]}
                {...primaryButton}
              >
                {primaryButton.text}
              </Button>
            )}
          </View>
          {showDismissButton && (
            <Button
              type="transparent"
              size="block"
              onPress={handleDismiss}
              accessibilityLabel="Dismiss"
            >
              Dismiss
            </Button>
          )}
        </BottomSheetView>
      </BaseBottomSheet>
    </View>
  )
}

const themedStyle = StyleService.create({
  background: {
    backgroundColor: 'theme.background.modal',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  handle: {
    paddingVertical: 8,
  },
  handleIndicator: {
    width: 48,
    height: 4,
    borderRadius: 2,
    backgroundColor: 'theme.surface.base',
  },
  container: {
    paddingHorizontal: 16,
  },
  scrollView: {
    flex: 0,
    marginTop: -20,
  },
  scrollContainer: {
    paddingTop: 20,
  },
  title: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 24,
  },
  flexGrow: {
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonSpacer: {
    width: 8,
    height: 12,
  },
})
