import { IndexPath, StyledComponentProps } from '@ui-kitten/components'
import { Dictionary } from 'lodash'
import { ViewStyle } from 'react-native'

export interface SelectGroupProps {
  groupedOptions: Dictionary<string[]>
  selectedOptions: Dictionary<string[]>
  onSelect: (options: Dictionary<string[]>) => void
  placeholder: string
  style: ViewStyle
  testID?: string
}

export interface SelectGroupState {
  selectedTypes: IndexPath[]
  value: string[]
}

export interface SelectGroupInnerProps extends SelectGroupProps, StyledComponentProps {}
