import { useSelector } from 'react-redux'
import moment from 'moment'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'

// Show migration screen at most once a day
export const useShowScanMigrationOnScan = () => {
  const user = useSelector(userSelector)
  const showScanDisableNoticeOnScan = useFeatureFlag(Feature.ScanDisableNoticeOnScan)
  const [scanDisabledNoticeShownAt] = useStorageValue(
    `${Storage.SCAN_DISABLED_NOTICE_SHOWN_AT_KEY}_${user?.id}`,
  )

  if (!showScanDisableNoticeOnScan) {
    return false
  }

  // they have never seen the notice after a scan
  if (!scanDisabledNoticeShownAt) {
    return true
  }

  const dismissedToday = moment(scanDisabledNoticeShownAt).isSame(moment(), 'day')

  // they already saw the notice today
  if (dismissedToday) {
    return false
  }

  return true
}
