export type Placements =
  | 'top'
  | 'top start'
  | 'top end'
  | 'bottom'
  | 'bottom start'
  | 'bottom end'
  | 'left'
  | 'left start'
  | 'left end'
  | 'right'
  | 'right start'
  | 'right end'
