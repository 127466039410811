import { useSelector } from 'react-redux'
import moment from 'moment'
import { Feature, useFeatureFlag } from '@src/components'
import { Storage } from '@src/utils'
import { userSelector, uxSensorModeKindSelector } from '@src/selectors/app'
import { useStorageValue } from '@src/utils/storage'
import { useIsEligibleForResearchSensor } from '@src/screens/Dexcom/Research/hooks/useIsEligibleForDexcomResearch'
import { sensorSelector } from '@src/selectors/sensor'
import { SensorModel } from '@src/types'
import { useIsActiveDexcom, useIsLibreLinkup } from '@src/utils/hooks'

export const useShowUpgradeToDexcomSensorBanner = () => {
  const user = useSelector(userSelector)
  const isEligibleForDexcomResearch = useIsEligibleForResearchSensor()

  const enableDexcomUpgradeBanner = useFeatureFlag(Feature.DexcomUpgradeBanner)
  const [sensorSwitchRejected] = useStorageValue(
    `${Storage.DEXCOM_SENSOR_SWITCH_REJECTED_KEY}_${user?.id}`,
  )

  if (sensorSwitchRejected || !enableDexcomUpgradeBanner || !isEligibleForDexcomResearch) {
    return false
  }

  return true
}

const ELIGIBLE_SENSOR_MODELS = [
  SensorModel.Libre1,
  SensorModel.Libre2,
  SensorModel.Libre2Eu,
  SensorModel.LibreUs,
]

export const useShowScanMigrationBanner = () => {
  const isScanDisableAnnouncementEnabled = useFeatureFlag(Feature.ScanDisableAnnouncements)
  const user = useSelector(userSelector)
  const currentSensor = useSelector(sensorSelector)
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  const [libreMigrationSwitchRejectedAt] = useStorageValue(
    `${Storage.LIBRE_MIGRATION_SWITCH_REJECTED_KEY}_${user?.id}`,
  )

  const isActiveDexcom = useIsActiveDexcom()
  const isLibreLinkup = useIsLibreLinkup()

  if (!isScanDisableAnnouncementEnabled) {
    return false
  }

  // only show to current libre 1 and 2 users
  if (
    !currentSensor ||
    !currentSensor.modelEnum ||
    !ELIGIBLE_SENSOR_MODELS.includes(currentSensor?.modelEnum)
  ) {
    return false
  }

  // user is already using linkup integration or in research
  if (!uxSensorModeKind || isLibreLinkup || isActiveDexcom) {
    return false
  }

  // banner has never been dismissed
  if (!libreMigrationSwitchRejectedAt) {
    return true
  }

  // banner was dismissed today
  const dismissedToday = moment(libreMigrationSwitchRejectedAt).isSame(moment(), 'day')

  if (dismissedToday) {
    return false
  }

  return true
}
