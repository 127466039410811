import React, { useEffect } from 'react'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CircleIcon } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { AppStackParamList } from '@src/navigation/types'
import { isInternetReachableSelector } from '@src/selectors/network'
import { useDexcomIntegration, useLibre3NativeEnabled } from '@src/utils/hooks'
import { getTutorialGroup } from '@src/utils/tutorials'
import { IconName, Text } from '@components/base'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import { useIsByosScannableSensorMode } from '@src/screens/OwnSensorOnboarding/hooks'
import { ActionsMenuWrapperModal } from './ActionsMenuWrapperModal'

const scanSensorText = 'Scan Sensor'

interface RouteInfo {
  name: keyof AppStackParamList | ''
  text: string
  icon: IconName
  onPress?: () => void
}

export const ActionsModal = () => {
  const styles = useStyleSheet(themedStyle)
  const isInternetReachable = useSelector(isInternetReachableSelector)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const goBack = useGoBack()
  const dispatch = useDispatch()

  const isScanDisabled = useDisableInAppScan()
  const isDexcomIntegration = useDexcomIntegration()
  const { uxSensorModePendingLibre3, currentSensorIsLibre3 } = useLibre3NativeEnabled()
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const isActivateOwnSensorFeatureAvailable = User.hasFeature(MobileAppFeature.ActivateOwnSensor)
  const isByosScannableSensorMode = useIsByosScannableSensorMode()

  useEffect(() => {
    dispatch({ type: 'users/fetch' })
  }, [dispatch])

  const routes: RouteInfo[] = [
    {
      name: 'AddMeal',
      text: 'Add a Meal',
      icon: 'fork-knife',
    },
    {
      name: 'AddActivity',
      text: 'Add Activity',
      icon: 'pulse',
    },
    {
      name: 'AddJournalEntry',
      text: 'Journal',
      icon: 'pen',
    },
    {
      name: 'AddMeasurement',
      text: 'Add a Measurement',
      icon: 'ruler',
    },
  ]

  if (isScanDisabled) {
    routes.unshift({
      name: '',
      text: 'Sensor',
      icon: 'scan',
      onPress: () => {
        // if you're byos, go to sensor settings (where we have the own_sensor survey)
        if (isActivateOwnSensorFeatureAvailable) {
          navigation.navigate('SensorSettings')
          return
        }
        // if you don't have the right subscription
        if (!isCGMFeatureAvailable) {
          navigation.navigate('Subscriptions')
          return
        }
        // you're a core monthly cgm user with an active libre 3 or dexcom connection
        if (currentSensorIsLibre3 || isDexcomIntegration) {
          navigation.navigate('SensorSettings')
        } else {
          // you are a core monthly cgm user without an active sensor
          // or you have an active Libre 1 or 2
          navigation.navigate('TutorialsGroup', {
            group: getTutorialGroup(uxSensorModePendingLibre3),
          })
        }
      },
    })
  } else {
    routes.unshift({
      name: '',
      text: scanSensorText,
      icon: 'scan',
      onPress: () => {
        if (isActivateOwnSensorFeatureAvailable) {
          if (isByosScannableSensorMode) {
            navigation.navigate('AddScan')
            return
          }
          navigation.navigate('SensorSettings')
          return
        }

        if (!isCGMFeatureAvailable) {
          navigation.navigate('Subscriptions')
          return
        }

        navigation.navigate('AddScan')
      },
    })
  }

  const onActionPress = (routeInfo: RouteInfo) => {
    if (routeInfo.name) {
      navigation.replace(`${routeInfo.name}`)
    } else {
      goBack()
      routeInfo.onPress && routeInfo.onPress()
    }
  }

  const renderActionItem = (routeInfo: RouteInfo, index: number) => {
    const { text, icon } = routeInfo
    const isActionDisabled = !isInternetReachable && text !== scanSensorText
    const disabledStyle = isActionDisabled ? styles.disabledAction : null
    const spacingStyle = { marginBottom: index === routes.length - 1 ? 0 : 16 }

    return (
      <TouchableOpacity
        testID={`ActionsModal/${text}`}
        key={text}
        style={[styles.action, disabledStyle, spacingStyle]}
        activeOpacity={0.6}
        onPress={() => onActionPress(routeInfo)}
        accessibilityLabel={text}
        disabled={isActionDisabled}
      >
        <CircleIcon name={icon} style={styles.icon} />
        <Text type="large" bold>
          {text}
        </Text>
      </TouchableOpacity>
    )
  }

  return <ActionsMenuWrapperModal>{routes.reverse().map(renderActionItem)}</ActionsMenuWrapperModal>
}

const themedStyle = StyleService.create({
  action: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  disabledAction: {
    opacity: 0.3,
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
