import React from 'react'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import {
  useIsByosScannableSensorMode,
  useShouldShowByosOnboarding,
} from '@src/screens/OwnSensorOnboarding/hooks'
import { useDispatchAsync } from '@src/utils'
import { UiStateNames } from '@src/types'
import { AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import { TutorialGroup, TutorialGroups } from '../models/tutorials.types'
import { TutorialPreview } from './TutorialPreview'

export const TutorialsGroup = ({ tutorialGroup }: { tutorialGroup: TutorialGroup }) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const { summary, tutorials = [] } = tutorialGroup
  const byosOnboardingTutorial = useShouldShowByosOnboarding()
  const dispatchAsync = useDispatchAsync()
  const isByosScannableSensorMode = useIsByosScannableSensorMode()
  const isLibreLinkupTutorial = Number(tutorialGroup.id) === TutorialGroups.LibreLinkup
  const actionButtonText = isLibreLinkupTutorial
    ? 'Skip Tutorials and Connect Your Sensor'
    : 'Skip Tutorials and Activate Your Sensor'

  const onScanButtonPress = async () => {
    if (byosOnboardingTutorial) {
      await dispatchAsync({
        type: 'app/upsertUiState',
        payload: {
          name: UiStateNames.OwnSensorTutorialCompleted,
          value: true,
        },
      })
      if (isByosScannableSensorMode) {
        await dispatchAsync({
          type: 'app/upsertUiState',
          payload: {
            name: UiStateNames.OwnSensorOnboardingCompleted,
            value: true,
          },
        })
        navigation.replace('AddScan', {
          action: 'activate',
          parentScreen: 'Drawer',
          parentScreenParams: { screen: 'Dashboard' },
        })
        return
      }
    }

    if (isLibreLinkupTutorial) {
      navigation.navigate('LibreLinkupIntegration')
      return
    }
    navigation.navigate('AddScan', { action: 'activate' })
  }

  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Text type="regular" style={styles.summaryText}>
          {summary}
        </Text>
        {tutorials.map((tutorial) => (
          <TutorialPreview key={tutorial.id} tutorial={tutorial} />
        ))}
      </ScrollView>
      <Button
        type="primary"
        size="block"
        accessibilityLabel="Activate your sensor"
        onPress={onScanButtonPress}
      >
        {actionButtonText}
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  summaryText: {
    marginVertical: 16,
    textAlign: 'center',
  },
})
